import { GetStaticPropsContext } from 'next';

import { fetchPageProps } from '@/lib/sanity/fetchers/fetchPageProps';
import { homepage } from '@/lib/sanity/queries/homepage';
import { type PageProps } from '@/lib/sanity/queries/page';
import { Page } from '@/components/Page';
import { schemaOrganization } from '@/components/SeoSchema/consts';
import { RenderSEOSchema } from '@/components/SeoSchema/Render';

export const config = {
  maxDuration: 30,
};

export async function getStaticProps({ draftMode }: GetStaticPropsContext) {
  const props = await fetchPageProps({ pageQuery: homepage, draftMode });

  if (!props.page) {
    return {
      notFound: true,
    };
  }

  return { props: { ...props, isHomepage: true } };
}

export default function Home(props: PageProps) {
  return (
    <>
      <RenderSEOSchema
        schemaData={[
          {
            '@context': 'https://schema.org/',
            '@type': 'WebPage',
            name: 'Transcend',
          },
          schemaOrganization,
        ]}
      />
      <Page sections={props.page?.sections} />
    </>
  );
}
